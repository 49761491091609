@import url('https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&display=swap');

body {
  margin: 0;
  font-family: 'Goudy Bookletter 1911', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Goudy Bookletter 1911', serif;
}

font-face {
  font-family: 'Goudy Bookletter 1911', serif;
}



